<template>
  <v-navigation-drawer :color="ant" permanent absolute class="" app>
    <v-list-item>
      <v-list-item-content>
        <v-img :src="require('@/assets/img/Genius.png')" alt="Essay Genius" />
      </v-list-item-content>
    </v-list-item>

    <v-row>
      <v-col class="ml-6 mt-5">
        Account status:
        <v-btn :color="yellowOrange" class="mt-2" vertical small>
          Probation
        </v-btn>
      </v-col>
      <v-divider vertical class="mt-12 mb-10"></v-divider>
      <v-col class="mt-5">
        Orders limit:
        <p class="float-right mt-2 mr-7">8/15</p>
      </v-col>
    </v-row>

    <v-divider class="mt-3 mb-3"></v-divider>

    <v-list-item dense link to="/profile/view" >
      <v-list-item-avatar size="30">
        <v-img
        :src="getSession.avatar"
        v-if="getSession.avatar"
        >
        </v-img>
        <v-icon v-else> mdi-account </v-icon>
      </v-list-item-avatar>

      <v-list-item-content class="ml-n2">
        <v-list-item-title>{{ getSession.username }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-action>
    </v-list-item>
        
    <v-list-item dense link to='/writer/all'>
      <v-list-item-icon>
        <v-icon>mdi-lock-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="ml-n4">
        <v-list-item-title>Available Orders</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item dense link to='/writer/bidding'>
      <v-list-item-icon>
        <v-icon>mdi-account-check</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="ml-n4">
        <v-list-item-title>Bidding</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item dense link to='/writer/in-progress'>
      <v-list-item-icon>
        <v-icon>mdi-pencil</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="ml-n4">
        <v-list-item-title>In Progress</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item dense link to='/writer/completed'>
      <v-list-item-icon>
        <v-icon>mdi-lock-check</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="ml-n4">
        <v-list-item-title>Completed</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item dense link to='/writer/cancelled'>
      <v-list-item-icon>
        <v-icon>mdi-lock-open-remove</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="ml-n4">
        <v-list-item-title>Cancelled</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <template v-slot:append>
      <v-list dense nav>
        <v-divider></v-divider>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon right dark>mdi-logout</v-icon>
            <!-- <i class="far fa-edit"></i> -->
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import router from "@/router";
import colorMxn from "@/mixins/colorMxn";
import { mapActions, mapMutations, mapGetters } from "vuex";
import connection from '@/socket/utils';

export default {
  name: "NavigationDrawer",
  mixins: [colorMxn],
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" }
      ],
      right: null,
      ant: "#273142",
      chatStatus: false
    };
  },
  computed: {
    ...mapGetters(['getChatStatus', 'getSession', 'getNotification', 'getTopBarNotification']),
  },
  watch: {
    chatStatus: function(val) {
      this.passChat(val);
    },
    getChatStatus: function(data) {
      this.chatopen(data);
    }
  },
  async mounted() {
    connection.connect();
    this.socket = connection.subscribe(`notification:${this.getSession.id}`, this.handleMessageAdd)
    await this.getBalance();
  },
  methods: {
    ...mapMutations(['setChatStatus', 'setNotification', 'setWholeNotification' ]),
    ...mapActions(['clearCache', 'getBalance']),
    passChat() {
      const data = {
        status: false,
        id: null
      };
      this.setChatStatus(data);
    },
    chatopen(data) {
      this.chatStatus = data.status;
    },
    async logout() {
      await this.clearCache();
      router.push("/auth/login");
    },
    handleMessageAdd(message) {
      const { type, data } = message;
      switch (type) {
        case 'balance':
          this.refreshAuthToken();
          break;
        case 'notification':
          if (data) {
            this.setWholeNotification(1);
          }
          this.$popNotification.play();
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style scoped>
.ant {
  background: #273142;
}

a {
  text-decoration: none;
}
</style>
